import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="dark-mode:text-neige text-bleu md:mb-20 md:mt-0 md:px-0 relative z-10 max-w-2xl px-8 py-20 mx-auto mt-10 mb-10 text-center">
        <h1>NOT FOUND</h1>
        <p className="md:text-5xl font-serif text-4xl italic font-bold leading-tight">
          Oops - let's try <Link to={`/`} className='underline'>the homepage</Link>.
        </p>
    </div>
  </Layout>
)

export default NotFoundPage
